import React, { FC } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Principal from './pages/Principal';

import "./assets/css/style.css";
import DeviceConfig from './config/Device.config';
import Device from './interfaces/Device.interface';
import NoSoportado from './pages/NoSoportado';

const App: FC = () => {
  const device: Device = DeviceConfig.getData();
  return (
    <Router>
      <Routes>
        <Route path="/" element={device.isDesktop ? < NoSoportado /> : <Principal />} />
      </Routes>
    </Router>
  );
}

export default App;
