import React, { FC } from "react"

const Block: FC<{ height: string }> = ({ height }) => {
    return (
        <div style={{ height }} ></div>
    )
}

export default Block

