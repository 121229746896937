import React, { FC } from 'react';
import colors from '../config/Colors.config';


const NoSoportado: FC = () => {
    return (
        <section style={{ width: "100%", height: "100vh", backgroundColor: colors.azul_1 }} >

        </section>
    );
}

export default NoSoportado