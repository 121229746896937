import React, { FC, useEffect, useRef, useState } from 'react';
import DeviceConfig from '../config/Device.config';
import Device from '../interfaces/Device.interface';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Col, Label, Row } from 'reactstrap';
import STitle from '../components/STitle.component';
import colors from '../config/Colors.config';
import { Zoom, Rotate } from 'react-reveal';

import imgMasaOrilla from "../assets/img/masas/orilla.png";
import imgMasaSarten from "../assets/img/masas/sarten.jpg";
import imgMasaCrunchy from "../assets/img/masas/crunchy.jpg";
import imgMasaOriginal from "../assets/img/masas/original.png";
import Block from '../components/Block.component';
// import { useInView } from 'react-intersection-observer';

import logoGPM from "../assets/img/logo/logoHorizontalAzul2.png";
import variado01 from "../assets/img/masas/variado01.png";

import fondo01 from "../assets/img/fondos/01.png";
import fondo02 from "../assets/img/fondos/02.png";
import fondo03 from "../assets/img/fondos/03.png";
import fondo04 from "../assets/img/fondos/04.png";
import fondo05 from "../assets/img/fondos/05.png";
import TimeTool from '../tools/Time.tool';

const Principal: FC = () => {

  const device: Device = DeviceConfig.getData();
  const [zIndexArray, setZIndexArray] = useState({ "0": 1, "1": 2 });

  const [viewFondos, setViewFondos] = useState<boolean>(false);
  const [viewFondoRojo, setViewFondoRojo] = useState<boolean>(false);
  const [viewFondoDominos, setViewFondoDominos] = useState<boolean>(false);

  const targetRef1 = useRef(null);
  const targetRef2 = useRef(null);

  const handleIntersection1: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log('blanco');
        setZIndexArray({ "0": 2, "1": 1 });
      }
    });
  };

  const handleIntersection2: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log('azul');
        setZIndexArray({ "0": 1, "1": 2 });
      }
    });
  };

  useEffect(() => {

    const observer1 = new IntersectionObserver(handleIntersection1, {
      root: null, // use the viewport as the root
      rootMargin: '0px', // no margin
      threshold: 0.5, // Puedes ajustar el valor según tus necesidades
    });
    const observer2 = new IntersectionObserver(handleIntersection2, {
      root: null, // use the viewport as the root
      rootMargin: '0px', // no margin
      threshold: 0.5, // Puedes ajustar el valor según tus necesidades
    });

    if (targetRef1.current) {
      observer1.observe(targetRef1.current);
    }
    if (targetRef2.current) {
      observer2.observe(targetRef2.current);
    }

    // Cleanup
    return () => {
      if (targetRef1.current) {
        observer1.unobserve(targetRef1.current);
      }
      if (targetRef2.current) {
        observer2.unobserve(targetRef2.current);
      }
      
    };

  }, []);

  const mostrarFondos = async () => {
    TimeTool.sleep(1).then(() => {
      setViewFondos(true);
    });
    TimeTool.sleep(1.1).then(() => {
      setViewFondoRojo(true);
    });
    TimeTool.sleep(2).then(() => {
      setViewFondoDominos(true);
    });

  }

  return (

    device.isMovil ?
      <div>
        < div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: colors.blanco,
            position: "relative",
            zIndex: 3
          }}
        >
          {
            viewFondoDominos &&
            <Rotate duration={1000}  >
                <img
                  src={fondo05}
                  alt="No se pudo cargar la imagen"
                  style={{
                    width: "100%",
                    height: "100vh",
                    position: "absolute",
                    zIndex: 4
                  }}
                />
            </Rotate>
          }
          {
            viewFondoRojo &&
            <Zoom>
              <img
                src={fondo04}
                alt="No se pudo cargar la imagen"
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  zIndex: 3
                }}
              />
            </Zoom>
          }
          {
            viewFondos &&
            <Zoom>
              <img
                src={fondo03}
                alt="No se pudo cargar la imagen"
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  zIndex: 2
                }}
              />
            </Zoom>
          }
          {
            viewFondos &&
            <Zoom>
              <img
                src={fondo02}

                alt="No se pudo cargar la imagen"
                style={{
                  width: "100%",
                  height: "100vh",
                  position: "absolute",
                  zIndex: 4
                }}
              />
            </Zoom>
          }
          <img
            src={fondo01}
            alt="No se pudo cargar la imagen"
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              zIndex: 1
            }}
            onLoad={()=>{
              console.log("pss")
              mostrarFondos();
            }}
          />
        </div>
        <div></div>
        <div

          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: colors.azul_1,
            position: "fixed",
            top: 0,
            zIndex: zIndexArray[0],
            padding: 0,
          }}
        >
          <Block height={"8vh"} />
          <Row style={{ marginRight: 0, marginLeft: 0 }} >
            <Col>
              <Accordion style={{ backgroundColor: colors.azul_1 }} >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon style={{ color: colors.blanco }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col style={{ minWidth: "25%" }} >
                      <img
                        src={imgMasaOriginal}
                        alt="No se pudo cargar la imagen"
                        style={{
                          width: "100%",
                          borderRadius: 12
                        }}
                      />
                    </Col>
                    <Col>
                      {/* <Typography>{item.title}</Typography> */}
                      <Label style={{ fontFamily: "Pizza Press Fill", fontSize: 25, color: colors.blanco }} >
                        Masa Original
                      </Label>
                    </Col>
                  </Row>
                </AccordionSummary>
                <AccordionDetails style={{ textAlign: "justify" }} >
                  <Typography style={{ fontFamily: "Pizza Press Fill", color: colors.blanco }} >
                    La original y clásica masa fresca hecha al momento con orilla dorada y espolvoreada de especias que le dan nuestro toque único.
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Col>
          </Row>
        </div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: colors.azul_1,
            position: "fixed",
            top: 0,
            zIndex: zIndexArray[1],
            padding: 0,
          }}
        >
          <Block height={"8vh"} />
          <Row style={{ marginRight: 0, marginLeft: 0 }} >
            <Col>
              <Accordion style={{ backgroundColor: colors.blanco }} >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon style={{ color: colors.azul_1 }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col style={{ minWidth: "25%" }} >
                      <img
                        src={imgMasaOrilla}
                        alt="No se pudo cargar la imagen"
                        style={{
                          width: "100%",
                          borderRadius: 12
                        }}
                      />
                    </Col>
                    <Col>
                      {/* <Typography>{item.title}</Typography> */}
                      <Label style={{ fontFamily: "Pizza Press Fill", fontSize: 20, color: colors.azul_1 }} >
                        Orilla rellena de queso
                      </Label>
                    </Col>
                  </Row>
                </AccordionSummary>
                <AccordionDetails style={{ textAlign: "justify" }} >
                  <Typography style={{ fontFamily: "Pizza Press Fill", color: colors.azul_1 }} >
                    Masa fresca hecha al momento con deliciosa orilla dorada rellena de queso mozzarella derretido y espolvoreada con especias.
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Col>
          </Row>
        </div>
        <div style={{ width: "100%", height: "100vh", backgroundColor: colors.blanco, zIndex: 2, position: "static", }}  > </div>
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: colors.blanco,
            zIndex: 2,
            position: "relative",
          }}
        >
          <div style={{ height: "5vh" }} >
            <button ref={targetRef2} style={{ height: "5vh", backgroundColor: "transparent", color: "transparent", borderColor: "transparent" }} > Cambiar </button>
          </div>
          <div style={{ marginTop: "90vh", height: "5vh" }}>
            <button ref={targetRef1} style={{ height: "5vh", backgroundColor: "transparent", color: "transparent", borderColor: "transparent" }} > Cambiar </button>
          </div>
        </div>
        <div style={{ width: "100%", height: "100vh", backgroundColor: colors.blanco, zIndex: 2, position: "static", }}  ></div>
      </div>
      :
      null


  )
}

export default Principal;
