import Colors from "../interfaces/Colors.interface";

const colors: Colors = {
    azul_1: "#006491",
    azul_2: "#122F51",
    rojo: "#E31837",
    blanco: "#FFFFFF",
    negro: "#000000",
    gris_1: "#F4F4F4",
    warning: "#FFBA1F",
    amarillo: "#F5DF0D",
}

export default colors;